import { PlayerTable } from "../../../ui/PlayerTable/PlayerTable";
import { useMainViewList } from "../../hooks/useMainViewList";

export const MainView = () => {
  const {
    state: { byArea },
  } = useMainViewList();

  return (
    <div>
      <h1>Main View</h1>
      <div>
        <h2>Goalkepper</h2>
        <PlayerTable data={byArea.goalkepper} />
      </div>

      <div>
        <h2>Defender</h2>
        <PlayerTable data={byArea.defender} />
      </div>

      <div>
        <h2>Midfield</h2>
        <PlayerTable data={byArea.midfield} />
      </div>

      <div>
        <h2>Winger</h2>
        <PlayerTable data={byArea.winger} />
      </div>

      <div>
        <h2>Striker</h2>
        <PlayerTable data={byArea.striker} />
      </div>
    </div>
  );
};
