import { useMemo } from "react";
import { MainViewResponse } from "../../data-access/interfaces/MainViewResponse";
import { useGetMainViewQuery } from "../../data-access/queries/main-view.queries";

type Positions = "GK" | "DC" | "DL" | "DR" | "CM" | "AM" | "LW" | "RW" | "ST";

type Areas = "goalkepper" | "defender" | "midfield" | "winger" | "striker";

type ByAreaMapper = Readonly<Record<Areas, MainViewResponse[]>>;

const positionToArea: Readonly<Record<Positions, Areas>> = {
  GK: "goalkepper",
  DC: "defender",
  DL: "defender",
  DR: "defender",
  CM: "midfield",
  AM: "midfield",
  LW: "winger",
  RW: "winger",
  ST: "striker",
};

const emptyAreas: ByAreaMapper = {
  goalkepper: [],
  defender: [],
  midfield: [],
  winger: [],
  striker: [],
};

const reduceByArea = (acc, cur) => {
  const key = positionToArea[cur.position];
  const areaData = acc[key];

  return {
    ...acc,
    [key]: [...areaData, cur],
  };
};

export const useMainViewList = () => {
  const data = useGetMainViewQuery();

  const groupedByArea = useMemo(
    () => data.reduce<ByAreaMapper>(reduceByArea, emptyAreas),
    [data]
  );

  return { state: { raw: data, byArea: groupedByArea } };
};
