import { PlayerTableProps } from "./PlayerTable.types";

export const PlayerTable = ({ data }: PlayerTableProps) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Position</th>
          <th>Name</th>
          <th>Nationality</th>
          <th>Age</th>
          <th>Personality</th>
        </tr>
      </thead>
      <tbody>
        {data.map((player, index) => (
          <tr key={`${player.name}-${index}`}>
            <td>{player.position}</td>
            <td>{player.name}</td>
            <td>{player.nat}</td>
            <td>{player.age}</td>
            <td>{player.personality}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
