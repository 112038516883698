import { MainViewResponse } from "../interfaces/MainViewResponse";
import viewData from "../json/data.json";

export class MainViewAPI {
  view = {
    get: (): MainViewResponse[] => {
      return viewData;
    },
  };
}
